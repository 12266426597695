import { Box, Button } from '@mui/material';
import { useCognitoAuth } from '../auth/CognitoAuthContext';

export default function LoginCustomerP() {
    const { loginWithRedirectProvider } = useCognitoAuth();
    // REACT_APP_CUSTOMER_SAML_IDP will contain the customer SAML IDP name
    return <Box sx={{ maxWidth: 400, mx: 'auto', p: 3, textAlign: 'center' }}>
        <Box component="form" onSubmit={(e) => {
            e.preventDefault();
            loginWithRedirectProvider(process.env.REACT_APP_ADMIN_SAML_IDP as string);
        }}>
            <Button
                type="submit"
                variant="outlined"
                color="primary"
                size='small'
                sx={{ mt: 2 }}
            >
                Log In with AWS IAM
            </Button>

        </Box>
    </Box>
}
